import { v4 as randomUUID } from 'uuid'

export function track(key, val = undefined) {
	let uid = localStorage.getItem('webstats.userId')
	if (!uid) localStorage.setItem('webstats.userId', uid = randomUUID())
	key = encodeURIComponent(key)
	val = val && encodeURIComponent(val)
	let url = `https://api.civmc.tk/webstats/event/${uid}/${key}`
	if (val !== undefined) url += `/${val}`
	window.fetch(url + '/empty.gif')
}
